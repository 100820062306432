import { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useToasts } from "react-toast-notifications";
import useIsInViewport from "use-is-in-viewport";

/* Primary message component */

export const Message = ({ children, appearance, toastId, style, showBar }) => {
  const levelClasses = {
    error: "bg-error text-page-white",
    warning: "bg-caution text-ink-black",
    success: "bg-teal text-ink-black",
    info: "bg-gray3 text-ink-black",
  }[appearance];

  const [exiting, setExiting] = useState(false);
  const { removeToast, updateToast } = useToasts();

  const msgRef = useRef();
  const [isInViewport, wrappedTargetRef] = useIsInViewport({
    target: msgRef,
    threshold: 100,
  });

  useEffect(() => {
    if (showBar) {
      updateToast(toastId, { hide: isInViewport });
    }
  }, [toastId, isInViewport, updateToast, showBar]);

  const initialDelay = 4000;
  const fadeDuration = 500;

  useEffect(() => {
    // set to fade out 500ms before toast is removed
    const fadeOutDelay = initialDelay - fadeDuration;
    let timer = setTimeout(() => {
      setExiting(true);
      timer = setTimeout(() => {
        // remove toast when animation completes
        removeToast(toastId);
      }, fadeDuration);
    }, fadeOutDelay);

    return () => clearTimeout(timer);
  }, [removeToast, toastId, children]);

  return showBar ? (
    <div
      ref={wrappedTargetRef}
      style={{
        ...style,
        animation: `ease-out ${
          exiting ? `${fadeDuration}ms fadeOut` : "0.2s fadeIn"
        } forwards`,
      }}
      className={cx("py-2 shadow duration-500", levelClasses)}
      id={toastId}
    >
      <div className="content-spacing flex">{children}</div>
    </div>
  ) : null;
};

const Index = ({ showBar }) => {
  const { toastStack } = useToasts();

  return (
    <div className="messages absolute w-full top-16 z-20">
      {toastStack.map(({ content, appearance, id }) => (
        <Message
          appearance={appearance}
          key={id}
          toastId={id}
          showBar={showBar}
        >
          {content}
        </Message>
      ))}
    </div>
  );
};

export default Index;
