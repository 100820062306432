import { createGlobalStyle } from "styled-components";
import tw from "twin.macro";

export default createGlobalStyle`
  .section-title {
      ${tw`py-16 text-textGray-darker font-medium text-xl text-center`};
  }

  .heading {
    ${tw`text-textGray-darker font-title`};
    line-height: 1.2;
    font-size: 32px;
    ${({ theme }) => theme.media["md"]} {
      font-size: 36px;
    }
  }

    h1 {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
    }
`;
