import { ReactNode, useContext, useState } from "react";
import { useRouter } from "next/router";
import { parseCookies, setCookie } from "nookies";
import { get } from "lodash";
import { AdminContext } from "contexts/AdminProvider";
import Icon from "components/atoms/Icon";
import styled from "styled-components";

const StyledSiteBannerText = styled.div`
  a {
    color: ${({ theme }) => theme.colors["page-white"]};
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export interface SiteBannerData {
  line1: string;
  line2: string;
  dismissable: boolean;
  path_regex: string;
  cookie_name: string;
  id: number;
}

export interface SiteBannerProps {
  siteBanner?: SiteBannerData;
  dismissed?: boolean;
  handleDismiss?: () => void;
  children?: ReactNode;
  className?: string;
}

/** Stateless, reusable banner for display at the site-level */
export const SiteBanner = ({
  siteBanner,
  dismissed = false,
  children,
  handleDismiss = () => null,
  className = "",
}: SiteBannerProps) => {
  if (!siteBanner || dismissed) {
    return null;
  }

  return (
    <div
      className={`bg-perriwinkle text-page-white text-center small md:text-base ${className}`}
    >
      <div className="content-spacing py-1">
        <div
          className="grid items-center"
          style={{ gridTemplateColumns: "1fr auto" }}
        >
          <div className="flex justify-center items-center">
            {children ? (
              children
            ) : (
              <StyledSiteBannerText
                className="pr-2 site-banner-text"
                dangerouslySetInnerHTML={{ __html: siteBanner?.line1 }}
              />
            )}

            {siteBanner?.line2 && (
              <StyledSiteBannerText
                className="site-banner-text"
                dangerouslySetInnerHTML={{ __html: siteBanner?.line2 }}
              />
            )}
          </div>
          {siteBanner?.dismissable && (
            <button
              className="flex text-2xl"
              type="button"
              aria-label="Close"
              data-role="close"
              onClick={() => handleDismiss?.()}
            >
              <Icon name="times" title="dismiss" size="1rem" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/** Stateful wrapper around the SiteBanner for checking pathnames and managing state with cookies */
const ConnectedSiteBanner = () => {
  const { siteBanners } = useContext(AdminContext) as {
    siteBanners: SiteBannerData[];
  };
  const { pathname } = useRouter();
  const pathRegex = (banner: SiteBannerData) => RegExp(banner.path_regex);
  const siteBanner = siteBanners.find((banner) =>
    pathRegex(banner).test(pathname)
  );

  // use cookies for dismissing banner
  const cookies = parseCookies();
  const bannerCookieName = get(siteBanner, "cookie_name", "");
  const isDismissed = cookies[bannerCookieName] === "hide";
  const [dismissed, setDismissed] = useState(isDismissed);

  const handleDismiss = () => {
    setCookie(null, bannerCookieName, "hide", {});
    setDismissed(true);
  };

  return (
    <SiteBanner
      siteBanner={siteBanner}
      dismissed={dismissed}
      handleDismiss={handleDismiss}
    />
  );
};

export default ConnectedSiteBanner;
