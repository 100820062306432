import React, { memo, useContext } from "react";
import cx from "classnames";
import NavDropdown from "components/Layout/Navbar/NavDropdown";
import { CLUB_LINKS, GIFT_LINKS } from "components/Layout/Navbar/config";
import { StyledNavBar } from "components/Layout/Navbar/styles";
import MobileNav from "components/Layout/Navbar/MobileNav";
import LiteratiLogo from "@/atoms/Icon/literati";
import { makeKidsUrl } from "utils";
import { AccountContext } from "contexts/AccountProvider";
import { AdminContext } from "contexts/AdminProvider";
import {
  NavBarLinkNext,
  NavBarLinkNextDark,
} from "components/Layout/Navbar/navbarStyles";

import { LINKS, DEFAULT_PROMO_CODE } from "globalConfig";
import Link from "next/link";
import { MyAccountDropDown } from "./MyAccountDropDown";

export type NavBarProps = {
  isHomePage: boolean;
  giftingCTA: boolean;
};

const Navbar = memo(({ giftingCTA, isHomePage }: NavBarProps) => {
  const { isAuthenticated } = useContext(AccountContext) as any;
  const { isFeatureOn }: any = useContext(AdminContext);

  let getStartedLink = giftingCTA ? (LINKS as any).gift : LINKS.subscribe;

  const getStartedLabel = giftingCTA ? "Get Gifting" : "Get Started";

  if (isFeatureOn("checkout-promo") && !giftingCTA) {
    getStartedLink = `${getStartedLink}?extCouponCode=${DEFAULT_PROMO_CODE}`;
  }

  const removeHamburger = isHomePage && !isAuthenticated;

  return (
    <StyledNavBar
      className={cx("navbar content-spacing", {
        "grid-cols-2 md:grid-cols-3": removeHamburger,
      })}
      style={!removeHamburger ? { gridTemplateColumns: "1fr auto 1fr" } : {}}
    >
      <div
        className={cx("navbar-left", {
          "hidden md:flex": removeHamburger,
        })}
      >
        <MobileNav isAuthenticated={isAuthenticated} />
        <NavDropdown label="Clubs" items={CLUB_LINKS} analyticsLabel="clubs" />
        {isAuthenticated ? (
          <Link href={LINKS.giveGift}>
            <a
              className="unstyled-link hidden md:inline-block pr-4"
              data-analytics="nav-gifts-cta"
            >
              Gifts
            </a>
          </Link>
        ) : (
          <NavDropdown
            label="Gifts"
            items={GIFT_LINKS}
            analyticsLabel="gifts"
          />
        )}
        <a
          href={"/kids/reviews/"}
          className="unstyled-link hidden md:inline-block pr-4"
          data-analytics="nav-reviews-cta"
        >
          Reviews
        </a>
      </div>
      <Link href="https://www.literati.com">
        <a
          className={cx("navbar-logo", {
            "justify-self-start md:justify-self-center": removeHamburger,
          })}
        >
          <LiteratiLogo />
        </a>
      </Link>
      <div className="navbar-right">
        {isAuthenticated ? (
          <MyAccountDropDown />
        ) : (
          <>
            <span
              className={
                removeHamburger ? "inline-block" : "hidden md:inline-block"
              }
            >
              <NavBarLinkNextDark
                data-analytics="nav-sign-in-cta"
                href={makeKidsUrl(LINKS.login)}
                variant="button"
                legacyBehavior={undefined}
              >
                Log In
              </NavBarLinkNextDark>
            </span>
            <span
              className={
                removeHamburger ? "hidden md:inline" : "hidden sm:inline"
              }
            >
              <NavBarLinkNext
                data-analytics="nav-get-started-cta"
                href={getStartedLink}
                variant="button"
                legacyBehavior={undefined}
              >
                {getStartedLabel}
              </NavBarLinkNext>
            </span>
          </>
        )}
      </div>
    </StyledNavBar>
  );
});

export default Navbar;
