import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

const stickyNavIn = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;

const stickyNavOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  80% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

export const StyledStickyNav = styled.div`
  ${tw`h-16 fixed z-20 w-full`}
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  .inner {
    ${tw`h-full flex items-center justify-between`}
  }

  &.nav-hidden {
    animation-duration: 300ms;
    animation-name: ${stickyNavOut};
  }

  &.nav-visible {
    pointer-events: auto;
    animation-duration: 200ms;
    animation-name: ${stickyNavIn};
  }
`;
