import { useContext, useState } from "react";
import cx from "classnames";
import { StyledStickyNav } from "./styles";
import { AccountContext } from "contexts/AccountProvider";
import { AdminContext } from "contexts/AdminProvider";
import useScrollCallback from "utils/hooks/useScrollCallback";
import LiteratiLogo from "@/atoms/Icon/literati";
import { NavBarLinkNext } from "components/Layout/Navbar/navbarStyles";

import Link from "next/link";
import { LINKS, DEFAULT_PROMO_CODE } from "globalConfig";

const StickyNav = ({ giftingCTA }) => {
  const [visible, setVisible] = useState(null);
  const { isAuthenticated } = useContext(AccountContext);
  const { isFeatureOn } = useContext(AdminContext);

  let getStartedLink = giftingCTA ? LINKS.gift : LINKS.subscribe;

  let getStartedLabel = giftingCTA ? "Get Gifting" : "Get Started";

  if (isFeatureOn && isFeatureOn("checkout-promo") && !giftingCTA) {
    getStartedLink = `${getStartedLink}?extCouponCode=${DEFAULT_PROMO_CODE}`;
  }

  const scrollCallback = (scrollPosition) => {
    if (scrollPosition > 700) {
      setVisible(true);
    } else {
      // only set visible to false if previously visible
      setVisible((visible) => visible && false);
    }
  };

  useScrollCallback(scrollCallback);

  const show = visible === true;
  const hide = visible === false;

  return (
    !isAuthenticated && (
      <StyledStickyNav
        id="sticky-nav"
        className={cx("bg-ink-black md:hidden", {
          "nav-visible": show,
          "nav-hidden": hide,
        })}
      >
        <div className="content-spacing inner">
          <Link href="/">
            <a>
              <LiteratiLogo />
            </a>
          </Link>
          <NavBarLinkNext
            data-analytics="nav-get-started-cta"
            href={getStartedLink}
            variant="button"
            legacyBehavior={undefined}
          >
            {getStartedLabel}
          </NavBarLinkNext>
        </div>
      </StyledStickyNav>
    )
  );
};

export default StickyNav;
