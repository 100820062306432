import { SiteBanner, SiteBannerData } from "components/Layout/SiteBanner";
import { useRouter } from "next/router";

/**
 * Normally this data is supplied by a the api /api/site-banners, 
 * but since it's rendered in a different location, we have to hard-code it.
 */
const giftingSiteBannerConfig: SiteBannerData = {
  line1:
    '<p><strong>Need a gift card for a Literati Book Fair? </strong><a href="https://efairs.literati.com/product-category/gift-cards/" rel="noopener noreferrer" target="_blank"><strong>Get it here!</strong></a></p>',
  line2: "",
  dismissable: false,
  // only show on the /kids/gifts page
  path_regex: "^/gifts$",
  cookie_name: "unused-gift-options-cookie",
  id: 999999,
};

/**
 * One-off SiteBanner for display only on the /kids/gifts page.
 * Must be rendered from the Layout component for formatting reasons.
 */
export const SiteBannerGiftingOptions = () => {
  const { pathname } = useRouter();

  if (!RegExp(giftingSiteBannerConfig.path_regex).test(pathname)) return null;

  return <SiteBanner siteBanner={giftingSiteBannerConfig} dismissed={false} />;
};
