import Navbar from "components/Layout/Navbar";
import { Footer } from "@literati/public-ui-shared-react";
import StickyNav from "components/Layout/Navbar/StickyNav";
import Messages from "components/Layout/Messages";
import ConnectedSiteBanner from "components/Layout/SiteBanner";
import Head from "next/head";
import MorganStyles from "globalStyles/morgan";
import React from "react";
import styled from "styled-components";
import cx from "classnames";
import { SiteBannerGiftingOptions } from "components/Layout/SiteBannerGiftingOptions";

const StyledLayout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .main {
    position: relative;
    flex-grow: 1;

    section {
      position: relative;
    }
  }
`;

const Layout = (props) => {
  const {
    children,
    id,
    noContainer,
    pageTitle,
    preloadData,
    showGiftingCTA,
    isHomePage,
    className,
  } = props;

  return (
    <StyledLayout className={cx("layout", className)} id={id}>
      <Head>
        <title key="title">{pageTitle}</title>
        {preloadData && (
          <link rel="preload" href={preloadData.url} as={preloadData.as} />
        )}
      </Head>
      <ConnectedSiteBanner />
      <header className="bg-ink-black relative">
        <StickyNav giftingCTA={showGiftingCTA} isHomePage={isHomePage} />
        <Navbar giftingCTA={showGiftingCTA} isHomePage={isHomePage} />
        <Messages showBar={true} />
      </header>
      <SiteBannerGiftingOptions />
      <main className="main">
        {noContainer ? (
          children
        ) : (
          <div className="content-spacing w-full">{children}</div>
        )}
      </main>
      <Footer />
      <MorganStyles />
    </StyledLayout>
  );
};

export function getPrimaryLayout(page) {
  const layoutProps = this?.props || {};
  return <Layout {...layoutProps}>{page}</Layout>;
}

Layout.defaultProps = {
  pageTitle: "Literati",
};

export default Layout;
