import styled from "styled-components";
import tw from "twin.macro";

export const StyledNavBar = styled.div`
  ${tw`h-16 text-page-white grid items-center bg-ink-black`}

  .navbar-left {
    justify-self: start;
    align-items: center;
  }

  .navbar-right {
    justify-self: end;
    display: flex;
    align-items: center;
  }

  a.unstyled-link,
  button,
  #mobile-menu-toggle {
    &:focus {
      outline: none;
      ${tw`text-teal`}
    }
  }

  .navbar-hamburger {
    ${tw`relative z-10`}
    ${tw`block`}
    ${({ theme }) => theme.media["md"]} {
      ${tw`hidden`}
    }
    ${tw`text-white opacity-75 transition duration-300`}
    &:hover {
      ${tw`opacity-100`}
    }
    ${tw`leading-none`}
  }
  .hamburger-menu {
    ${tw`invisible`}
    ${({ theme }) => theme.media["md"]} {
      ${tw`hidden`}
    }
    ${tw`fixed inset-0 z-0`}
    ${tw`opacity-0 transition-opacity duration-300`}
    ${tw`bg-gray5 text-page-white`}
    ${tw`pt-16`}
    &.is-open {
      ${tw`visible opacity-100`}
    }
  }
  .hamburger-item,
  a.hamburger-item {
    ${tw`block px-4 text-center border-b border-gray4 py-2 no-underline text-inherit`}
    &:hover,
    &:focus,
    &:active {
      ${tw`bg-teal-light text-ink-black`}
    }
  }
`;
