import { useEffect } from "react";
import { throttle } from "lodash";

const useScrollCallback = (callback) => {
  useEffect(() => {
    const handleScroll = throttle(() => {
      const { scrollTop } = document.documentElement || document.body;
      callback(scrollTop);
    }, 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [callback]);
};

export default useScrollCallback;
