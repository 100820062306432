import Typography from "./_typography";
import Base from "./_base";

/*
 Used to implement new global styles provided by Morgan Co. only on layouts that import it.
 This allows for separation of styles that don't require individual namespacing.
*/

const MorganStyles = () => (
  <>
    <Typography />
    <Base />
  </>
);

export default MorganStyles;
