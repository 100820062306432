import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .layout {
    background: #fafafa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .main {
      flex-grow: 1;
    }
  }
`;
